/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

// type MetaProps = JSX.IntrinsicElements['meta'];

// type HtmlProps = JSX.IntrinsicElements['html'] & {
//   [key: string]: string;
// };

interface Props {
  description?: string;
  title: string;
}

export function Seo({ description = '', title }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  return (
    <Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

// meta={[
//   {
//     property: `og:title`,
//     content: title,
//   },
//   {
//     property: `og:description`,
//     content: metaDescription,
//   },
//   {
//     property: `og:type`,
//     content: `website`,
//   },
//   {
//     name: `twitter:card`,
//     content: `summary`,
//   },
//   {
//     name: `twitter:creator`,
//     content: site.siteMetadata.author,
//   },
//   {
//     name: `twitter:title`,
//     content: title,
//   },
//   {
//     name: `twitter:description`,
//     content: metaDescription,
//   },
// ].concat(meta)}
// title={title}
// titleTemplate={`%s | ${site.siteMetadata.title}`}
